import { graphql, useStaticQuery } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { useI18nLink, useServiceI18nLink } from "../../hooks/useI18nLink";
import { useOpenCookieBanner } from "../../hooks/useCookieBanner";
import { useServiceTree } from "../../hooks/useServiceTree";

// @ts-ignore
import logo from "../../assets/images/logo.png";

const socialIconMap = {
  linkedin: (
    <svg
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0"
      className="w-5 h-5"
      viewBox="0 0 24 24"
    >
      <path
        stroke="none"
        d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
      ></path>
      <circle cx="4" cy="4" r="2" stroke="none"></circle>
    </svg>
  ),
  glassdoor: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1772"
      height="2500"
      viewBox="0.6 0 680.6 959.3"
      className="w-5 h-5"
    >
      <path
        fill="currentColor"
        d="M545.2 259.5c0-2.7 2.2-5 4.9-5h126.3c2.7 0 4.8 2.4 4.8 5.1v562.8c0 75.5-60.8 136.9-136 136.9H136.8C61.5 959.3.6 898.1.6 822.4h544.6V259.5zM136.8 0C61.5 0 .6 61.4.6 137.1v562.8c0 2.7 2.3 5 5 5h126.2c2.7 0 5-2.3 5-5V137.1h544.4C681.2 61.4 620.4 0 545.2 0z"
      ></path>
    </svg>
  ),
};

const Footer = () => {
  const { t } = useTranslation();
  const i18nLink = useI18nLink();
  const { serviceLink } = useServiceI18nLink();
  const services = useServiceTree();
  const openCookieBanner = useOpenCookieBanner();

  const { company, contactMail, contactPhone, socials } = useStaticQuery(
    graphql`
      query FooterQuery {
        company: companyJson {
          id
          name
          piva
          address
        }
        contactMail: contactsJson(jsonId: { eq: "info" }) {
          name
          url
        }
        contactPhone: contactsJson(jsonId: { eq: "phone" }) {
          name
          url
        }
        socials: allSocialJson {
          nodes {
            id: jsonId
            name
            url
          }
        }
      }
    `
  );

  const navigation = {
    categories: [
      {
        name: "Links",
        links: [
          {
            name: t("navigationVoices.index"),
            url: "/",
          },
          {
            name: t("navigationVoices.about"),
            url: i18nLink("/chi-siamo/"),
          },
          {
            name: t("navigationVoices.join"),
            url: i18nLink("/lavora-con-noi/"),
          },
          {
            name: t("navigationVoices.blog"),
            language: "it",
            url: "/blog/",
          },
          {
            name: t("navigationVoices.privacy"),
            url: "/privacy/privacy-policy/",
          },
          {
            name: t("navigationVoices.cookiePreferences"),
            onClick: openCookieBanner,
          },
        ],
      },
      ...services.map((service) => ({
        name: t(`services:${service.id}.name`),
        link: serviceLink(service),
        links: service.solutions?.map((solution) => {
          return {
            name: t(`solutions:${solution.id}.name`),
            url: serviceLink(solution),
          };
        }),
      })),
    ],
    socialLinks: socials.nodes.map((social) => ({
      name: social.name,
      icon: socialIconMap[social.id],
      url: social.url,
    })),
  };

  return (
    <footer className="text-gray-600 body-font">
      <div className="border-t container mx-auto px-5 lg:px-10 py-24 flex md:flex-row flex-col md:space-x-12">
        <div className="text-center md:text-left">
          <Link
            to="/"
            className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900 mb-5"
          >
            <img src={logo} alt="Astrorei logo" className="h-12" />
          </Link>
          <p className="mt-2 text-sm text-gray-500 leading-tight whitespace-nowrap">
            {company.name}
          </p>
          <p className="mt-2 text-sm text-gray-500 leading-tight whitespace-nowrap">
            {company.address}
          </p>
          <p className="mt-2 text-sm text-gray-500 leading-tight whitespace-nowrap">
            C.F / P.IVA: {company.piva}
          </p>
          <a
            href={contactMail.url}
            className="mt-2 text-sm text-gray-500 leading-tight flex justify-center md:justify-start whitespace-nowrap hover:text-gray-700"
          >
            <svg
              className="h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
            <span>{contactMail.name}</span>
          </a>
          <a
            href={contactPhone.url}
            className="lg:hidden mt-2 text-sm text-gray-500 leading-tight flex justify-center md:justify-start whitespace-nowrap hover:text-gray-700"
          >
            <svg
              className="h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              />
            </svg>
            <span>{contactPhone.name}</span>
          </a>
          <div className="hidden lg:flex mt-2 text-sm text-gray-500 leading-tight justify-center md:justify-start whitespace-nowrap">
            <svg
              className="h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              />
            </svg>
            <span>{contactPhone.name}</span>
          </div>
        </div>
        <div className="text-center md:text-left grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-4 gap-y-12 mt-12 md:mt-0">
          {navigation.categories.map((category, i) => (
            <div key={i}>
              <p className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
                {category.link ? (
                  <Link key={i} to={category.link}>
                    {category.name}
                    <ChevronRightIcon className="inline h-5 w-5 -mt-[1px] ml-1" />
                  </Link>
                ) : (
                  category.name
                )}
              </p>
              <nav className="grid grid-cols-2 gap-x-4">
                {category.links &&
                  category.links.map((link, i) => {
                    const c = `block text-gray-600 hover:text-gray-800 hover:underline leading-relaxed cursor-pointer ${
                      link.class || ""
                    }`;

                    return link.url ? (
                      <Link
                        key={i}
                        to={link.url}
                        language={link.language}
                        className={c}
                      >
                        {link.name}
                      </Link>
                    ) : (
                      <p key={i} className={c} onClick={link.onClick}>
                        {link.name}
                      </p>
                    );
                  })}
              </nav>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="container mx-auto py-4 px-5 lg:px-10 flex justify-between items-center">
          <p className="text-gray-600 text-sm">
            © {new Date().getFullYear()} Astrorei
          </p>
          <div className="flex space-x-3">
            {navigation.socialLinks.map((socialLink, i) => (
              <a
                key={i}
                href={socialLink.url}
                target="_blank"
                className="block text-gray-500"
              >
                {socialLink.icon}
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
